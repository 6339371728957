import { jwtDecode } from 'jwt-decode';
interface MyJwtPayload {
  sub: string;
  name: string;
  exp: number;
}

const isTokenExpired = (token: string | null) => {
  if (token) {
    const decodedToken: MyJwtPayload = jwtDecode(token ? token : '');
    const currentTime = Date.now() / 1000;
    const isTokenExpired =
      (decodedToken.exp ? decodedToken.exp : 0) < currentTime;
    const isTokenValid =
      token === undefined || '' || null ? false : isTokenExpired;
    return isTokenValid;
  }
};

export default isTokenExpired;

export const superAdminValidation = (token: string | null) => {
  if (token) {
    const decodedToken: any = jwtDecode(token ? token : '');
    const currentTime = Date.now() / 1000;
    const isTokenExpired =
      (decodedToken.exp ? decodedToken.exp : 0) < currentTime;
    const roles = decodedToken.roles as string[];
    //console.log('Current user is Super admin:', roles.includes('SUPER_ADMIN'));
    return roles.includes('SUPER_ADMIN');
  }
};
